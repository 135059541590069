import React from 'react';
import { Home, FileText, Search, User } from 'tabler-icons-react';
import { useColorScheme, useHotkeys, useLocalStorage } from '@mantine/hooks';
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  GlobalStyles,
  NormalizeCSS,
  Group,
  Text,
  Anchor,
  Kbd,
  Space,
} from '@mantine/core';
import { SpotlightAction, SpotlightProvider } from '@mantine/spotlight';

interface LayoutProps {
  children: React.ReactNode;
}

const actions: SpotlightAction[] = [
  {
    title: 'Home',
    description: 'Get to home page',
    onTrigger: () => console.log('Home'),
    icon: <Home size={18} />,
  },
  {
    title: 'Posts',
    description: 'Read my blog posts',
    onTrigger: () => console.log('Posts'),
    icon: <FileText size={18} />,
  },
  {
    title: 'About',
    description: 'Get to about me page and see more about me',
    onTrigger: () => console.log('About'),
    icon: <User size={18} />,
  },
];

export function Layout({ children }: LayoutProps) {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'light' ? 'dark' : 'light'));

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      {/* Add your theme here */}
      <MantineProvider theme={{ colorScheme }}>
        {/* <SpotlightProvider
          shortcut={['mod + P', 'mod + K', '/']}
          highlightQuery
          actions={actions}
          searchIcon={<Search size={18} />}
          searchPlaceholder="Search..."
          nothingFoundMessage="Nothing found..."
        > */}
        <GlobalStyles />
        <NormalizeCSS />
        {children}
        {/* </SpotlightProvider> */}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
